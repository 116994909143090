<template>
  <div
    class="md:pr-6 lg:pr-0 pb-0 flex flex-row justify-start relative gap-6 bg-base-200 min-h-[calc(100vh-47px)]"
  >
    <div
      class="px-4 pb-10 w-full relative max-w-6xl md:w-[100%] shadow-panel bg-base-100"
    >
      <div class="md:sticky md:top-10 z-10 bg-base-100">
        <!-- BACK BUTTON -->
        <div v-if="slots.backButton" class="mt-6 mb-4">
          <slot name="backButton" />
        </div>

        <!-- TITLE - ACTIONS -->
        <div
          v-if="slots.title"
          class="relative px-0 pb-2 flex flex-wrap justify-between items-center border-b"
        >
          <slot name="title" />
          <slot name="actions" />
        </div>
      </div>

      <!-- SUMMARY BADGES -->
      <div v-if="slots.summaryBadges">
        <slot name="summaryBadges" />
      </div>

      <!-- MAIN CONTENT -->
      <div class="mt-6 pb-4 w-full">
        <div class="flex flex-col gap-5 px-0">
          <slot name="content" />
        </div>
      </div>
    </div>

    <!-- TABLE OF CONTENT -->

    <slot name="toc" />
  </div>
</template>

<script setup lang="ts">
  const slots = useSlots();
</script>
